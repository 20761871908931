import { useEffect, useState } from 'react';
import { ACCOUNT_TABS, EMPTY_PRODUCT, EMPTY_VARIANT, LOAD_MORE, PRODUCT_TYPES, YOTPO } from '@constants';
import {
	Button,
	Container,
	CustomerIdElement,
	Flex,
	Heading,
	Loading,
	MustachioedBeretWearer,
	Paragraph,
	Title,
	VerticalCard,
	YotpoActivityWidgetBanner,
} from '@components';
import { CustomerOrdersResponse } from '@services/shopify/operations/get-customer-orders';
import { useCustomer } from '@services/shopify';
import { useLoadYotpo } from '@services/yotpo';
import { normalizeProductType } from '@utils/normalizers/normalize-product';
import { NormalizedProduct } from '@ts/product';
import { useOrders } from '@services/poms';
import { getShopifyIdFromGID } from '@utils/shopify';
import styles from './Collection.module.scss';

const { BASE_FRAME, TOP_FRAME, ACCESSORY, GIFT_CARD } = PRODUCT_TYPES;

const transformData = ({ product }): NormalizedProduct => {
	return {
		...EMPTY_PRODUCT,
		name: product.title,
		type: product.variant.productType,
		variants: [
			{
				...EMPTY_VARIANT,
				option: product.variant.title,
				image: product.variant.image,
			},
		],
	};
};

export const CollectionGrid = ({
	title,
	products,
}: {
	title: string;
	products: CustomerOrdersResponse['customer']['orders'][number]['lineItems'];
}) => {
	return products.length ? (
		<Flex column gap={3} className={styles.grid}>
			<Container className={styles['collection-header']}>
				<Heading style={{ marginBottom: '0.4rem', fontSize: '2.4rem' }} tag='h5'>{`My ${title}`}</Heading>
				<Paragraph>{`${products.length} ${title}`}</Paragraph>
			</Container>
			<Container className={styles.products}>
				{products.map((product, index) => {
					const productNormalized = transformData({ product });
					return (
						<VerticalCard
							key={`${productNormalized.id}-${index}`}
							product={productNormalized}
							variant={productNormalized?.variants[0]}
							primaryAction='none'
							hoverable={false}
							showCollectionLozenge={false}
							ImgClickHandler={() => null}
						/>
					);
				})}
			</Container>
		</Flex>
	) : null;
};

CollectionGrid.displayName = 'CollectionGrid';

const Collection = () => {
	useLoadYotpo();
	const [page, setPage] = useState(1);
	const [isLastPage, setIsLastPage] = useState(false);
	const { data: customer } = useCustomer();
	const { data: ordersData, isLoading } = useOrders({ userId: getShopifyIdFromGID(customer?.id), page });
	const [groupedByType, setGroupedByType] = useState({
		[BASE_FRAME]: [],
		[TOP_FRAME]: [],
		[ACCESSORY]: [],
		[GIFT_CARD]: [],
	});
	const hasCollectedData = !!(
		groupedByType.ACCESSORY.length ||
		groupedByType.TOP_FRAME.length ||
		groupedByType.BASE_FRAME.length ||
		groupedByType[GIFT_CARD].length
	);

	useEffect(() => {
		if (ordersData) {
			const newGroupedByType = { ...groupedByType };

			for (let i = 0; i < ordersData.orders.length; i++) {
				const { line_items: lineItems } = ordersData.orders[i];
				for (let j = 0; j < lineItems.length; j++) {
					try {
						const { product_title: title, product_type } = lineItems[j];

						const type = normalizeProductType(product_type);
						if (type === 'LENS' || !Object.values(PRODUCT_TYPES).includes(type)) {
							continue;
						}
						newGroupedByType[type].push({
							title,
							variant: {
								title: lineItems[j].variant_title,
								productType: lineItems[j].product_type,
								image: {
									url: lineItems[j].image.src,
									altText: lineItems[j].image.alt,
									width: lineItems[j].image.width,
									height: lineItems[j].image.height,
								},
							},
						});
					} catch (error) {
						console.error('My Collection Error with: ', lineItems);
					}
				}
			}

			setGroupedByType(newGroupedByType);
			setIsLastPage(ordersData.pagination.last === page);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ordersData?.orders]);

	if (isLoading && !ordersData && !hasCollectedData) {
		return <Loading />;
	}

	return (
		<>
			<CustomerIdElement customer={customer} />
			<YotpoActivityWidgetBanner widgetId={YOTPO.ACTIVITY_WIDGET_STYLE2_ID} tab={ACCOUNT_TABS.MY_COLLECTION} />
			<Flex column maxWidth margin={'auto'} pad={4} gap={4} className={styles.collection}>
				{groupedByType.ACCESSORY.length +
					groupedByType.TOP_FRAME.length +
					groupedByType.BASE_FRAME.length +
					groupedByType[GIFT_CARD].length >
				0 ? (
					<>
						<CollectionGrid title='Base Frames' products={groupedByType[BASE_FRAME]} />
						<CollectionGrid title='Top Frames' products={groupedByType[TOP_FRAME]} />
						<CollectionGrid title='Accessories' products={groupedByType[ACCESSORY]} />
					</>
				) : (
					<Flex column center backgroundColor='gray1' borderRadius={3} pad={4} gap={4}>
						<MustachioedBeretWearer />
						<Flex column center>
							<Title>You have&#39;t placed any orders yet!</Title>
							<Paragraph>Once you do, your purchases will show up here.</Paragraph>
						</Flex>
						<Button href='/eyeglasses/all'>Shop Glasses</Button>
					</Flex>
				)}
				{!isLastPage && hasCollectedData && (
					<Button
						onClick={() => setPage(page + 1)}
						color='transparent-light'
						style={{
							width: '100%',
							height: '4.8rem',
							margin: '1.6rem 0',
						}}
						linkStyle
					>
						<Flex justify='center' fullWidth>
							{isLoading ? <Loading removeDefaultStyling /> : LOAD_MORE}
						</Flex>
					</Button>
				)}
			</Flex>
		</>
	);
};

Collection.displayName = 'Collection';

export default Collection;
